import { Injectable } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState, AuthClientConfig, AuthConfig, AuthService, IdToken, LogoutOptions, RedirectLoginOptions, User } from '@auth0/auth0-angular';
import { GetTokenSilentlyOptions, PopupLoginOptions} from '@auth0/auth0-spa-js/dist/typings/global';
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { AuthenticationService } from '@rss/ng-auth-service';
import { Auth0User } from 'src/app/modal/user.models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DAO } from 'src/app/modal/DAO';
import { ServiceLayer } from 'src/app/services/serviceLayer';
import { Router } from '@angular/router';
import { Settings } from './settings';
import { AppComponent } from '../app.component';
import { AppConfig } from '../app.config';
import { CommonService } from './commonService';

@Injectable()
export class Auth0UserService {
  public environment: string;
  public userLoaded$ = new BehaviorSubject<Auth0User>(null);
  public isUserAuthenticated: boolean = false;
  private userSubscription: Subscription;
  private idTokenSubscription: Subscription;
  private appStateSubscription: Subscription;
  private accessTokenSubscription: Subscription;
  private isLoadingSubscription: Subscription;
  private isAuthenticatedSubscription: Subscription;
  private errSubscription: Subscription;
  private myAuth0Config: AuthConfig;

  constructor(
    public authenticationServiceOld: AuthenticationService,
    public auth0Service: AuthService,
    public authClientConfig: AuthClientConfig,
    private serviceLayer: ServiceLayer,
    private dao: DAO,
    public commonService: CommonService, 
    private router: Router,
    private appComponent: AppComponent,
    private messageService: MessageService) { 
      this.myAuth0Config = this.authClientConfig.get()
      this.subscribeAuth0Events();
    }

    public changePassword(): Promise<boolean> {
      let resetPasswordPayload = {
        givenName: this.auth0User.name,
        email: this.auth0User.email
      };
      let payload = { "urlData": [], "payloadData": resetPasswordPayload };
      return this.commonService.post('workInProgress', 'changePassword', payload).toPromise()
        .then(result => { return result })
        .catch(error => Promise.reject(error));
    }
  
    public subscribeAuth0Events()
    {
      this.isAuthenticatedSubscription = this.auth0Service.isAuthenticated$.subscribe((value: boolean) => {
        
           this.isUserAuthenticated = !!value;
        
      });

      this.isLoadingSubscription = this.auth0Service.isLoading$.subscribe((value: boolean) => {    
       });        

      this.userSubscription = this.auth0Service.user$.subscribe((user) => {    
          if (user) {   
              this.auth0User = new Auth0User(user);
              this.getAccessTokenByClient();       
          }
          else {    
            this.auth0User = null;       
          }
      });
  
      this.appStateSubscription = this.auth0Service.appState$.subscribe((appState) => {
        console.log(appState);
      });
  
      this.idTokenSubscription = this.auth0Service.idTokenClaims$.subscribe(
          tkn => 
          {
              if (!!tkn)
              {               
                if (!!this.auth0User)
                  this.auth0User.idToken = tkn;                
              }
              else
              {                
                if (!!this.auth0User)
                  this.auth0User.idToken = null;                
              }
          });  
          
      this.errSubscription = this.auth0Service.error$.subscribe(
            err => 
            {
                if (!!err)
                {               
                  if (err.message == 'Unauthorized' )
                  {
                    alert("Access is denied. Please register to Auth0");
                    const options: LogoutOptions = 
                    {
                      logoutParams:{
                        returnTo: this.myAuth0Config.authorizationParams.redirect_uri          
                      }
                    }
                    this.auth0Service.logout(options);
                  }
                }
            });        
    }
  
    public login(): void {       
      let options: RedirectLoginOptions<AppState> = {
        appState: {
          returnTo: '/authenticating' // change this to '/home' in the future
        } 
      };
      this.auth0Service.loginWithRedirect();
    }
 
    public logout(): void {      
      if (!!this.auth0User.logOutUrl)
        this.auth0Service.logout( );    
    }

    public get logOutUrl(): string
    {
      if (!!this.auth0User?.idToken)
        return `https://${this.myAuth0Config.domain}/oidc/logout?id_token_hint=${this.auth0User.idToken.__raw}&post_logout_redirect_uri=${encodeURIComponent(this.myAuth0Config.authorizationParams.redirect_uri)}`;
      else
        return null;
    } 

    public unsubscribeAuth0Events()
    {
      this.userSubscription?.unsubscribe();
      this.idTokenSubscription?.unsubscribe();
      this.appStateSubscription?.unsubscribe();
      this.accessTokenSubscription?.unsubscribe();
      this.isLoadingSubscription?.unsubscribe();
      this.isAuthenticatedSubscription?.unsubscribe();
      this.errSubscription?.unsubscribe();
    }
  
    public getAccessTokenByClient(): void {
      if (this.auth0User?.accessToken != null  && this.auth0User.agencyCode != null)                  
        return;
   
      this.appComponent.triggerLoader('show');
      const options: Auth0ClientOptions = 
      {
        domain: this.myAuth0Config.domain,
        clientId: this.myAuth0Config.clientId,
        authorizationParams:{
          audience: this.myAuth0Config.authorizationParams["audience"],
          organization: this.myAuth0Config.authorizationParams.organization
        }
      }
      const auth0Client = new Auth0Client(options);
          try
          {
              auth0Client.getTokenSilently().then(
                  data=>{
                      console.log("AccessToken:  " + data);
                      this.auth0User.accessToken = data;                  
                      if (!!this.decodeJWT(data)) {
                        Auth0UserService.ResetUserInforToDao(this.dao, this.auth0User);
                        if (!!!this.auth0User.agencyCode) {
                          this.messageService.add({ key: 'registrationMsgPopup', sticky: true, severity: 'error', summary: 'You do not have access to the BSB Portal. Please contact your admin to get registered.', data: true });                          
                          this.appComponent.triggerLoader('hide');
                          return;
                        }
                        this.userLoaded$.next(this.auth0User);
                        if (!!!window.location.href?.includes("/home") 
                          && !!!window.location.href?.includes("/userManagement") 
                          && !!!window.location.href?.includes("/internalAdmin") 
                          && !!!window.location.href?.includes("/documentLibrary") 
                          )                
                        {
                            this.router.navigateByUrl("/home");
                            // change to /home in the future.
                            //This is in case browser cached existing login profile and we need to redirect user to home page
                        }
                      }
                    },
                err=>{
                      this.appComponent.triggerLoader('hide');
                      alert (err);
                    }
                  )
                  .catch((err) => {
                    this.appComponent.triggerLoader('hide');
                      alert (err);
                  })
                  .finally( () => {
                  
                  });         
        }
        catch ({ name, message }) {
          this.appComponent.triggerLoader('hide');
          alert (message);
        }
   }
  
    private decodeJWT(token) {
      if (!!!token)
        return;
      
      const parts = token.split('.');
      if (parts.length !== 3) {
          console.error('Invalid token format');
      }
      const header = JSON.parse(atob(parts[0]));
      const payload = JSON.parse(atob(parts[1]));
      this.auth0User.expireAt  = payload.exp;
      //In access token application meta data key is app-metadata
      const appMetaData = payload["app-metadata"];
      if (!!appMetaData)
      {
        const agentPortalApp = appMetaData.applications?.BSBAgentPortal;
        if(!!agentPortalApp)
        {
          for (let k of Object.keys(agentPortalApp)) {
            if (k.toLowerCase() == 'agencycode') {
              this.auth0User["agencyCode"] = agentPortalApp[k];
              break;
            }
          }
        }
        
        // parse role
        if(!!appMetaData["user-permissions"])
        {
          const agentPortalPermissions = appMetaData["user-permissions"].find((app: any) => app.application === "BSBAgentPortal");
          if (!!agentPortalPermissions && agentPortalPermissions.permissions != null && agentPortalPermissions.permissions.length > 0) {
            const role = agentPortalPermissions.permissions.find((permission: string) => permission.startsWith("role:"))?.replace("role:", "").trim().toLowerCase();
            this.auth0User["isAdmin"] = role == 'admin' ? true : false;
          }
        }

      }
      else if(payload["permissions"])
      {
        this.auth0User["isAdmin"] = !!payload["permissions"].find(x=>x=="admin")
        this.auth0User["agencyCode"] = payload["permissions"].find(x=>x.includes('agencycode'))?.replace("agencycode:", "");
      }
      return payload;
    }

    public get auth0User(): Auth0User 
    {
      return this.dao.auth0User1;
    }

    public set auth0User(value: Auth0User )
    {
      this.dao.auth0User1 = value;
    }

    public static ResetUserInforToDao(dao: DAO, auth0User: Auth0User)
    {
      dao.UserRole = auth0User.isAdmin ? 'admin' : '';
      dao.IsInternalUser = auth0User.isInternal;
      dao.userAgencyCode = auth0User.agencyCode;
      dao.userMail = auth0User.email;                                                  
      dao.userFullName = auth0User.given_name + ' ' + auth0User.family_name
    }
}
