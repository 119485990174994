 
    <div class="col-lg-9 float-right text-right mt-4 pt-3" *ngIf="!!!auth0UserService?.isUserAuthenticated">
            <span class="header_login_res mr-2"><a class="addcomm" (click)="login()">Log In</a></span>
    </div>
    <div class="col-lg-9 col-md-6 float-right text-right mt-4 pt-3" *ngIf="!!auth0User">
      <div class="dropdown">
          <button type="button" class="header_user_cls dropdown-toggle" data-toggle="dropdown"
              name="loginUserName" id="loginUserNameID">
              Welcome, {{auth0User.given_name}} {{auth0User.family_name}}
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="{{auth0UserService.logOutUrl}}" (click)="logout()">Sign Out</a>
            <a *ngIf="!auth0User.isInternal" class="dropdown-item" (click)="changePassword()">Change Password</a>          
          </div>
      </div> 
    </div>
