import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO, dropdown, RegistrationModal, userSearch } from '../../modal/DAO';
import { MessageService } from 'primeng/api';
import _ from 'lodash';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '@rss/ng-auth-service';
import { Table } from 'primeng/table';
import { DateService } from 'src/app/services/date.service';
import { CommonService } from 'src/app/services/commonService';
import { Settings } from 'src/app/services/settings';
import { AppConfig } from 'src/app/app.config';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';

@Component({
    selector: 'userManagementComponent',
    templateUrl: './userManagement.html'
})
export class userManagementComponent extends AuthenticatedUserComponent implements OnInit {
    public securityRoleList: dropdown;
    public deletedClaimSecurityID: string;
    @ViewChild('dt1') agentList!: Table;
    public hasEditUserClicked: boolean = false;
    public editUserData: RegistrationModal = new RegistrationModal();
    public editUserimsData: any;

    constructor(
      public dao: DAO, public appConfig: AppConfig,
        public serviceLayer: ServiceLayer,
        private messageService: MessageService,
        protected authenticationService: AuthenticationService,
        private dateService: DateService,
        private commonService: CommonService,
        private auth0UserService: Auth0UserService) {
        super(authenticationService);
    }

    public IsRoleEditable: boolean = false;

    ngOnInit(): void {
        this.dao.PolicySubTemplate = 'userManagement';

        if (this.appConfig.auth0Enabled)
        {
            this.auth0UserService.userLoaded$.subscribe(
                auth0User => {
                  if(!!auth0User?.accessToken)
                  {
                    this.GetAllUsers();
                    this.getEmptyClaimModel();
                  }
                });  
        }
        else
        {
            this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(() => {
                this.dao.userMail = this.userEmail;
                this.dao.IsInternalUser = this.isInternalUser;
                this.dao.UserRole = this.isAdmin ? "admin" : this.dao.UserRole;
                this.GetAllUsers();
                this.getEmptyClaimModel();
            });
        }
    }

    public async GetAllUsers() {
        await this.serviceLayer.GetAllUsers();
        await this.getRoles();
        await this.getAllAgencies();
    }

    public async DownloadUsers() {
        let filteredrows: any[] = (!!this.agentList.filteredValue) ? this.agentList.filteredValue : this.dao?.UserManagement?.UsersList;
        let rows: any[] = [];
        filteredrows.forEach(
            ele => {
                var auser = {
                    FirstName: ele.firstName,
                    LastName: ele.lastName, UserEmailID: ele.email,
                    AgencyCode: ele.agencyCode, AdminRole: ele.role,
                    CreatedTimeStamp: ele.createdDate,
                    ModifiedTimeStamp: ele.modifiedDate
                };
                rows.push(auser);
            }
        );
        if (!rows || !rows.length) {
            return;
        }

        const separator = ',';
        const keys = Object.keys(rows[0]);
        const csvContent =
            keys.join(separator) +
            '\n' +
            rows.map(row => {
                return keys.map(k => {
                    let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                    if (k.toLowerCase() == "createdtimestamp" || k.toLowerCase() == "modifiedtimestamp") {
                        cell = this.dateService.getDateTimeString(cell).trim();
                    }
                    else {
                        cell = cell instanceof Date ?
                            cell.toLocaleString() : cell.toString().replace(/"/g, '""');
                    }
                    if (cell.search(/("|,|\n)/g) >= 0) {
                        cell = `"${cell}"`;
                    }
                    return cell;
                }).join(separator);
            }).join('\n');

        let blob = new Blob(['\ufeff' + csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf(
            'Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        //if Safari open in new window to save file with random filename.
        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", "Agents List.csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    public async SecurityRoleList() {
        await this.getRoles();
    }
    public DeleteUserByClaimId(SecurityId: string, email: string): void {
        document.body.style.pointerEvents = "none";
        this.deletedClaimSecurityID = SecurityId;
        this.messageService.add({
            key: 'DeleteUser', sticky: true, severity: 'warn',
            summary: 'Are you sure, You want to delete ' + email + ' claims?'
        });
    }
    public async resetUserSearch() {
        this.dao.UserManagement.userSearch = new userSearch();
        await this.serviceLayer.GetAllUsers();
    }
    public userSearch() {
        this.dao.UserManagement.UsersList = _.cloneDeep(this.dao.UserManagement.tempUsersList);
        if (this.dao.UserManagement.userSearch.agencyCode != "") {
            this.dao.UserManagement.UsersList = this.dao.UserManagement.UsersList.filter(
                x => x.agencyCode == this.dao.UserManagement.userSearch.agencyCode
            );
        }
        if (this.dao.UserManagement.userSearch.firstName != "") {
            this.dao.UserManagement.UsersList = this.dao.UserManagement.UsersList.filter(
                x => x.firstName.toUpperCase().includes(this.dao.UserManagement.userSearch.firstName.toUpperCase())
            );
        }
        if (this.dao.UserManagement.userSearch.lastName != "") {
            this.dao.UserManagement.UsersList = this.dao.UserManagement.UsersList.filter(
                x => x.lastName.toUpperCase().includes(this.dao.UserManagement.userSearch.lastName.toUpperCase())
            );
        }
        if (this.dao.UserManagement.userSearch.emailID != "") {
            this.dao.UserManagement.UsersList = this.dao.UserManagement.UsersList.filter(
                x => x.email.toUpperCase().includes(this.dao.UserManagement.userSearch.emailID.toUpperCase())
            );
        }
    }

    private getEmptyClaimModel() {
        let payload = { "urlData": [this.dao.userCompany] }
        this.commonService.get('Agent', 'getClaimEmptyModel', payload).subscribe((event: any) => {
            if (event) {
                this.dao.ClaimEmptyModel = event;
            }
        });
    }
    public onReject(type) {
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
        this.deletedClaimSecurityID = '';
    }
    public async DeleteUser() {
        let IsSuccess = await this.serviceLayer.DeleteUserByClaimId(this.deletedClaimSecurityID);
        this.onReject('DeleteUser');
        if (IsSuccess == true) {
            this.messageService.add({ key: 'myKey2', severity: 'success', summary: 'Delete User', detail: "User has been successfully deleted. The user's access to BSB Non Fleet Portal has been removed." });
        } else {
            this.messageService.add({ key: 'myKey2', severity: 'error', summary: 'Delete User', detail: "You are not allowed to delete the user" });
        }
        await this.serviceLayer.GetAllUsers();

    }
    public async postAgencyRights(isCheckboxChecked) {
        let result: any = false;
        result = await this.serviceLayer.postAgencyRights(isCheckboxChecked);
        if (result != undefined && result != false) {
            this.messageService.add({ key: 'myKey2', severity: 'success', summary: 'User Rights', detail: "Saved Successfully." });
        }
    }

    public editUser(user): void {
        if (user != null) {
            this.editUserimsData = user;
            this.editUserData.givenName = user.firstName;
            this.editUserData.email = user.email;
            this.editUserData.agentCode = user.agencyCode;
            this.editUserData.surName = user.lastName;
            this.editUserData.securityRole = user.role;
            this.hasEditUserClicked = true;
        }
    }

    public resetEditUserData() {
        this.hasEditUserClicked = false;
        this.editUserimsData = null;
        this.editUserData = new RegistrationModal();
    }

    public async getAllAgencies() {
        if (this.dao.IsInternalUser) {
            let payload = { "urlData": [this.dao.userCompany, this.dao.userMail] }
            await this.commonService.get('Agent', 'GetAllAgencies', payload).subscribe((event: any) => {
                if (!!event) {
                    this.dao.BSBAgencyList = event;
                    event.forEach(element => {
                        if (!element.isEndorsementAccessed) {
                            if (this.dao.EndorsementList.length == 0) {
                                this.dao.EndorsementList[0] = element;
                            } else {
                                this.dao.EndorsementList.push(element);
                            }
                        } else if (element.isEndorsementAccessed) {
                            if (this.dao.EndorsementTargetList.length == 0) {
                                this.dao.EndorsementTargetList[0] = element;
                                this.dao.UserManagement.userSearch.inactiveUser = true;
                            } else {
                                this.dao.EndorsementTargetList.push(element);
                            }
                        }
                    });
                }
            });
        }
    }
    private getRoles() {
        let payload = { "urlData": [this.dao.userCompany] }
        this.commonService.get('Agent', 'RolesDropdown', payload).subscribe((event: any) => {
            if (event) {
                if (this.dao.IsInternalUser == true) {
                    event?.forEach(element => {
                        if (element.label == 'Administrator') {
                            element.label = 'IT Administrator'
                        }
                    });
                    this.dao.UserManagement.SecurityRoleList = event;
                } else {
                    this.dao.UserManagement.SecurityRoleList = event;
                }
            }
        });
    }
}
