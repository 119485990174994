<p-card class="userBorder">
    <form #f="ngForm" style="width:100%"
        (ngSubmit)="(f.valid && validateEmail()) ? saveUserData(f) : openToast('error')">
        <div class="row col-md-12">
            <div class="col-md-4">
                <div class="col-md-12 ipad_28 userManagementClass">
                    <label name="FirstNameLabel" id="FirstNameLabelID">
                        First Name <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-md-12 ipad_65">
                    <input class="form-control" [class.validate_error_cls]="f.submitted && !FirstName?.valid"
                        [(ngModel)]="userData.givenName" placeholder="First Name" type="text"
                        name="FirstName" id="FirstNameID" #FirstName="ngModel" required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-md-12 ipad_28 userManagementClass">
                    <label name="LastNameLabel" id="LastNameLabellID">
                        Last Name <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-md-12 ipad_65">
                    <input class="form-control" [class.validate_error_cls]="f.submitted && !LastName?.valid"
                        [(ngModel)]="userData.surName" type="text" name="LastName"
                        id="LastNameID" #LastName="ngModel" placeholder="Last Name" required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-md-12 ipad_28 userManagementClass">
                    <label name="emailIDLabel" id="emailIDLabelID">
                        Email ID <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-md-12 ipad_65">
                    <input class="form-control" [class.validate_error_cls]="f.submitted && (!RemailID?.valid || !validateEmail())"
                        [(ngModel)]="userData.email" type="text" name="RemailID" id="RemailID"
                        #RemailID="ngModel" placeholder="Email ID" required
                        [disabled]="isEditUser">
                    <div *ngIf="f.submitted && !validateEmail()">
                        <span class="text-danger">
                            Enter valid email address
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 mb-3" style="margin-top: 10px;">
            <div class="col-md-4">
                <div class="col-md-12 ipad_28 userManagementClass">
                    <label name="AgencyCodeLabel" id="AgencyCodeLabelID">
                        Agency Code<span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-md-12 ipad_65">
                    <p-autoComplete required [suggestions]="dao.RegistrationModal.AgencyCodeList"
                        [(ngModel)]="userData.agentCode" name="AgentCode" #AgentCode="ngModel"
                        id="AgentCodeID" *ngIf ="!isEditUser" class="autocomplete agencyCodeAC" 
                        [class.validateAutoComplete_error_cls]="f.submitted && !AgentCode?.valid"
                        placeholder="Agency Code" forceSelection="true" (completeMethod)="getAgencyList($event)" 
                        [disabled]="dao.IsInternalUser == false">
                    </p-autoComplete>

                    <input class="form-control" *ngIf="isEditUser" [(ngModel)]="userData.agentCode" type="text" 
                        name="AgentCode" id="AgentCode" #AgentCode="ngModel" disabled>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-md-12 ipad_28 userManagementClass">
                    <label name="SecurityRoleLabel" id="SecurityRoleLabelID">
                        Security Role <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-md-12 ipad_65">
                    <p-dropdown placeholder="Security Role"
                        [options]="dao.UserManagement.SecurityRoleList"
                        [(ngModel)]="userData.securityRole" name="SecurityRole"
                        id="SecurityRoleID" #SecurityRole="ngModel" required
                        [class.validateDrodown]="f.submitted && !SecurityRole?.valid">
                    </p-dropdown>
                </div>
            </div>
            <div class="col-md-4  text-right">
                <div class="col-md-12 ipad_28 userManagementClass  text-right">
                    <label>&ensp; </label>
                </div>
                <div class="col-md-12 ipad_65">
                    <button class="btnsuccess" title="save" name="save" id="save"
                        style="margin-right: 10px;" data-toggle="modal" type="submit">
                        <i class="{{isEditUser ? 'fa fa-check' : 'fa fa-plus'}}" aria-hidden="true"></i>
                        {{isEditUser ? "Update" : "Create New User"}}
                    </button>
                    <button class="btnsuccess" title="Reset" name="Rreset" id="RresetID"
                        data-toggle="modal" (click)="isEditUser ? closeEditUserPopup() : resetRegistrationModel()" type="button">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                        {{isEditUser ? "Cancel":"Reset"}}
                    </button>
                </div>
            </div>
        </div>
    </form>
</p-card>