<div class="container box_sec mt-4">
    <div class="row" style="margin-top:35px;">
        <h6 class="createnewuser-btn">
            <i class="fa fa-user-plus" aria-hidden="true" style="margin-right: 3px;"></i>Create New User
        </h6>
        <div class="col-lg-12">
            <app-user-creation [userData]="dao.RegistrationModal"></app-user-creation>
        </div>
    </div>

    <div class="row" style="margin-top:35px;" *ngIf="dao?.IsInternalUser == true">
        <div class="col-lg-12 mt-2">
            <div class="row">
                <h6 class="createnewuser-btn" style="width: 12%;">
                    <i class="fa fa-server" aria-hidden="true" style="margin-right: 3px;"></i>User Rights
                </h6>
                <div class="col-lg-12">
                    <p-card class="userBorder">
                        <div class="row">
                            <div class="col-md-4 mt-4 text-center">
                                <div class="p-field-checkbox">
                                    <p-checkbox name="groupname" id="isactiveID" type="checkbox" binary="true"
                                        checked="true" value="true"
                                        [(ngModel)]="dao.UserManagement.userSearch.inactiveUser" inputId="ny"
                                        (onChange)="postAgencyRights(true)">
                                    </p-checkbox>
                                    <label class="ml-2 mb-0" for="ny">Endorsement</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card px-3 py-3 bg_card" *ngIf="dao.UserManagement.userSearch.inactiveUser">

                                    <div class="row" style="margin: 0px;">
                                        <label>Endorsement</label>
                                        <div class="first_box"
                                            *ngIf="dao?.EndorsementList?.length > 0 || dao?.EndorsementTargetList?.length > 0">
                                            <p-pickList [source]="dao?.EndorsementList"
                                                [target]="dao?.EndorsementTargetList" [sourceStyle]="{'height':'30rem'}"
                                                [targetStyle]="{'height':'30rem'}">
                                                <ng-template let-endo pTemplate="item">
                                                    <div>{{endo?.code}}</div>
                                                </ng-template>
                                            </p-pickList>
                                        </div>
                                        <div class="col-md-12 ipad_65" style="text-align: right; margin-top: 10px;">
                                            <button class="btnsuccess" title="saveuserRights" name="saveuserRights"
                                                id="saveuserRightsID" style="margin-right: 10px;" data-toggle="modal"
                                                type="button" (click)="postAgencyRights(false)">
                                                <i class="fa fa-save" aria-hidden="true"></i>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin-top:35px;">
        <div class="col-lg-12 mt-2">
            <div class="row">
                <h6 class="createnewuser-btn" style="width: 12%;">
                    <i class="fa fa-search" aria-hidden="true" style="margin-right: 3px;"></i>User Search
                </h6>
                <div class="col-lg-12">
                    <p-card class="userBorderSearch">
                        <div class="row col-md-12">
                            <div class="col-md-4">
                                <div class="col-md-6 ipad_28 userManagementClass">
                                    <label name="agencyCodeLabel" id="agencyCodeLabelID">Agency Code </label>
                                </div>
                                <div class="col-md-12 ipad_65">
                                    <input class="form-control" [(ngModel)]="dao.UserManagement.userSearch.agencyCode"
                                        type="text" name="agencyCode" id="agencyCodeID" #agencyCode="ngModel"
                                        placeholder="Agency Code" [disabled]="dao.IsInternalUser == false">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="col-md-12 ipad_28 userManagementClass">
                                    <label name="firstNameLabel" id="firstNameLabelID">First Name </label>
                                </div>
                                <div class="col-md-12 ipad_65">
                                    <input class="form-control" [(ngModel)]="dao.UserManagement.userSearch.firstName"
                                        type="text" name="firstName" id="firstNameID" #firstName="ngModel"
                                        placeholder="First Name">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="col-md-12 ipad_28 userManagementClass">
                                    <label name="lastNameLabel" id="lastNameLabelID">Last Name </label>
                                </div>
                                <div class="col-md-12 ipad_65">
                                    <input class="form-control" [(ngModel)]="dao.UserManagement.userSearch.lastName"
                                        type="text" name="lastName" id="lastNameID" #lastName="ngModel"
                                        placeholder="Last Name">
                                </div>
                            </div>
                            <div class="row col-md-12" style="margin-top: 10px;"></div>
                            <div class="col-md-4">
                                <div class="col-md-12 ipad_28 userManagementClass">
                                    <label name="emailIDLabel" id="emailIDLabelID">Email ID </label>
                                </div>
                                <div class="col-md-12 ipad_65">
                                    <input class="form-control" [(ngModel)]="dao.UserManagement.userSearch.emailID"
                                        type="text" name="emailID" id="emailID" #emailID="ngModel"
                                        placeholder="Email ID">
                                </div>
                            </div>
                            <div class="col-md-8  text-right">
                                <div class="col-md-12 ipad_28 userManagementClass  text-right">
                                    <label>&ensp; </label>
                                </div>
                                <div class="col-md-12 ipad_65">
                                    <button class="btnsuccess" title="Search" name="Search" id="SearchID"
                                        style="margin-right: 10px;" data-toggle="modal" (click)="userSearch()">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                        Search
                                    </button>
                                    <button class="btnsuccess" title="Reset" name="reset" id="resetID"
                                        data-toggle="modal" (click)="resetUserSearch()">
                                        <i class="fa fa-refresh" aria-hidden="true"></i>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
            <p-card class="userBorderList">
                <div class="row" style="margin-right: 5px; justify-content: right;display: flex;align-items: center;">
                    <span class="p-input-icon-left p-ml-auto" style="margin-right: 10px;">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" style="padding-left: 30px; border: #115740 solid 1px;"
                                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search keyword" />
                    </span>
                    <button class="btnsuccess" title="Download Users" name="btnDownloadUsers" id="btnDownloadUsers"
                                data-toggle="modal" (click)="DownloadUsers()">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download Users
                    </button>                
                </div>
                <div class="table-responsive">
                    <p-table class="table llh_table p-datatable-striped" [value]="dao?.UserManagement?.UsersList" #dt1
                        styleClass="p-datatable-striped" [rows]="10" [paginator]="true"
                        [globalFilterFields]="['firstName','lastName','email','agencyCode','role']">pt
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 10%;">First Name</th>
                                <th style="width: 10%;">Last Name</th>
                                <th style="width: 20%;">User Email ID</th>
                                <th style="width: 6%;">Agency</th>
                                <th style="width: 10%;">Role</th>
                                <th>Created Timestamp</th>
                                <th>Last Modified</th>                                
                                <th style="width: 10%;text-align: center;">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template let-user let-i=rowIndex pTemplate="body">
                            <tr [pSelectableRow]="user">
                                <td style = "word-wrap: break-word" title="{{user?.firstName}}">
                                    {{user?.firstName}}
                                </td>
                                <td style = "word-wrap: break-word" title="{{user?.lastName}}">
                                    {{user?.lastName}}
                                </td>
                                <td style = "word-wrap: break-word" title="{{user?.email}}">
                                    {{user?.email}}
                                </td>
                                <td style = "word-wrap: break-word" title="{{user?.agencyCode}}">
                                    {{user?.agencyCode}}
                                </td>
                                <td title="{{user?.role}}">
                                    <div>{{user?.role == 'it' ? 'IT' : user?.role ==
                                        'basic' ? 'Basic': user?.role == 'admin'
                                        ? 'Administrator' : user?.role}}</div>
                                </td>
                                <td style = "word-wrap: break-word" title="{{user?.createdDate}}">
                                    {{user?.createdDate | date:"MM/dd/yyyy hh:mm a"}}
                                </td>
                                <td style = "word-wrap: break-word" title="{{user?.modifiedDate}}">
                                    {{user?.modifiedDate  | date:"MM/dd/yyyy hh:mm a"}}
                                </td>
                                <td style="text-align: center;" title="{{user?.firstName}}">
                                    <i class="fa fa-pencil-square-o deleteUserbtn-blue"
                                        (click)="editUser(user)">
                                    </i>
                                    <i class="pi pi-trash deleteUserbtn"
                                        (click)="DeleteUserByClaimId(user?.securityIdentifier,user?.email)"
                                        style="padding-left: 30px;">
                                    </i>
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-card>
        </div>
    </div>
    <p-toast key="myKey2" position="top-center"></p-toast>

    <p-toast position="center" key="DeleteUser" (onClose)="onReject('DeleteUser')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnDeleteUserYes"
                            id="btnDeleteUserYesID" (click)="DeleteUser('')">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnDeleteUserNo"
                            id="btnDeleteUserNoID" (click)="onReject('DeleteUser')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-dialog header="" [(visible)]="hasEditUserClicked"
        class="dialog_bulk_edit edit-user-popup" [modal]="true" (onHide)="resetEditUserData()">
        <div class="row">
            <h6 class="createnewuser-btn">
                <i class="fa fa-user mr-1" aria-hidden="true"></i>Edit User
            </h6>
            <div class="col-lg-12 editUser">
                <app-user-creation  [userData]="editUserData" [imsData]="editUserimsData" [isEditUser]="true"  (closeButtonClicked)="resetEditUserData()"></app-user-creation>
            </div>
        </div>
    </p-dialog>
</div>
